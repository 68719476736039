import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from '../auth.service';
import { Observable } from 'rxjs';
import { Auth, authState } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthGuard {
  constructor(
    private auth: Auth,
    private router: Router,
    private authService: AuthService
  ) {}
  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise(resolve => {
      authState(this.auth).subscribe(user => {
        if (user === null) {
          if (environment.isDemo) {
            this.router.navigate(['/demo']);
          } else {
            this.router.navigate(['/login']);
          }
        }
        resolve(user !== null);
      });
    });
  }
}

export const canActivateAuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return inject(AuthGuard).canActivate();
};

export class ConsumerGuard {
  constructor(
    private auth: Auth,
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise(resolve => {
      authState(this.auth).subscribe(user => {
        if (user === null) {
          this.router.navigate(['/login']);
        }

        resolve(user !== null);
      });
    });
  }
}
